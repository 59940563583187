<template>
    <div>
        <div class="innings_tabs cr-submain-tab">
            <ul>
                <li>
                    <a id="wormg" :class="(type == 'worm') ? 'selected': ''" style="cursor: pointer" class="selected" @click="getGraph('worm'); type = 'worm'" href="javascript:;">
                        <span>{{$t('cric.WORM GRAPH')}}</span>
                    </a>
                </li>
                <li>
                    <a id="manhattang" :class="(type == 'manhattan') ? 'selected': ''"  style="cursor: pointer" @click="getGraph('manhattan'); type = 'manhattan'" href="javascript:;">
                        <span>{{$t('cric.MANHATTAN GRAPH')}}</span>
                    </a>
                </li>
                <li>
                    <a id="runrateg" :class="(type == 'runrate') ? 'selected': ''" style="cursor: pointer" @click="getGraph('runrate'); type = 'runrate'" href="javascript:;">
                        <span>{{$t('cric.RUN RATE GRAPH')}}</span>
                    </a>
                </li>
            </ul>
            <div class="clearfix"></div>
        </div>
        <div id="worm_graph">
            <div class="performance-chart" id="chart" style="margin-left: -26px" ></div>
        </div>
        <div id="manhattan_graph" style="display:none">
            <div id="chart2" style="margin-left: -20px" ></div>
        </div>
        <div id="runrate_graph" style="display:none">
            <div class="performance-chart" id="runrate" style="margin-left: -26px"></div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    export default {
        name: "performance",

        data() {
            return {
                type: '',
            }
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$store.getters.getMatchDetailResponse.team1.name,
                '%AWAY_TEAM%': this.$store.getters.getMatchDetailResponse.team2.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_performance_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_performance_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.cricket_match_performance_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        mounted() {
          //  this.$loadScript(this.$vuePublicPath("/worm_graph.js").replace(/"/g,''));
          // console.log(this.$vuePublicPath("/worm_graph.js"));
            this.$loadScript(this.$vuePublicPath("/worm_graph.js"));
            this.getGraph('worm');
        },
        serverPrefetch() {
        },
        methods: {
            getGraph(type) {
                this.type = type;
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$MATCH_PERFORMANCE_API.replace(':match', this.$route.params.slug), {
                        params: {
                            type: type,
                        }
                    })
                    .then(response => {
                        if (response.data) {
                          console.log('as',response.data.result)
                            this.loadGraph(response.data.result, type);

                        }
                        this.is_data_ready = true;
                    })
            },
            loadGraph(data1, type) {
                if(type == 'worm') {
                    $('#manhattang,#runrateg').removeClass('selected');
                    $('#worm_graph').show();
                    $('#manhattan_graph,#runrate_graph').hide();
                    let columnsInfo = {};
                    // console.log('st',this.$store.getters.getMatchDetailResponse.team1.name);
                    columnsInfo[this.$store.getters.getMatchDetailResponse.team1.id] = this.$store.getters.getMatchDetailResponse.team1.name;
                    columnsInfo[this.$store.getters.getMatchDetailResponse.team2.id] = this.$store.getters.getMatchDetailResponse.team2.name;
                    $("#runrate").empty();
                    $("#runrate").width(document.querySelector('.container').clientWidth * 1);
                    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)){
                        $("#chart").height(document.querySelector('.container').clientWidth * 0.95);
                    }
                    else
                        $("#chart").height(document.querySelector('.container').clientWidth * 0.45);
                    let muliSeriesChartConfig = {
                        mainDiv: "#chart",
                        colorRange: ["#1A223B", "#ec004f"],
                        data: data1,
                        columnsInfo: columnsInfo,
                        xAxis: "over",
                        yAxis: "runs",
                        label: {
                            xAxis: "Over",
                            yAxis: "Runs"
                        },
                        requireCircle: false,
                        requireLegend: true
                    };
                    var muliSeriesChart = multiSeriesLineChart(muliSeriesChartConfig);

                }
                if(type == 'manhattan') {
                    $('#wormg,#runrateg').removeClass('selected');
                    $('#manhattan_graph').show();
                    $('#worm_graph,#runrate_graph').hide();
                    let columnsInfo = {};
                    columnsInfo[this.$store.getters.getMatchDetailResponse.team1.id] = this.$store.getters.getMatchDetailResponse.team1.name;
                    columnsInfo[this.$store.getters.getMatchDetailResponse.team2.id] = this.$store.getters.getMatchDetailResponse.team2.name;
                    $("#chart2").empty();
                    $("#chart2").width(document.querySelector('.container').clientWidth * 1);
                    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent))
                        $("#chart2").height(document.querySelector('.container').clientWidth * 0.80);
                    else
                        $("#chart2").height(document.querySelector('.container').clientWidth * 0.45);
                    var barChartConfig = {
                        mainDiv: "#chart2",
                        colorRange: ["#1A223B", "#ec004f"],
                        data: data1,
                        columnsInfo: columnsInfo,
                        xAxis: "over",
                        yAxis: "runs",
                        label: {
                            xAxis: "Over",
                            yAxis: "Runs"
                        },
                        requireLegend: true
                    };
                    // console.log(barChartConfig);
                    var groupChart = groupBarChart(barChartConfig);
                }
                if(type == 'runrate') {
                    $('#wormg,#manhattang').removeClass('selected');
                    $('#runrate_graph').show();
                    $('#worm_graph,#manhattan_graph').hide();
                    let columnsInfo = {};
                    columnsInfo[this.$store.getters.getMatchDetailResponse.team1.id] = this.$store.getters.getMatchDetailResponse.team1.name;
                    columnsInfo[this.$store.getters.getMatchDetailResponse.team2.id] = this.$store.getters.getMatchDetailResponse.team2.name;
                    $("#runrate").empty();
                    $("#chart").empty();
                    $("#runrate").width(document.querySelector('.container').clientWidth * 1);
                    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent))
                        $("#runrate").height(document.querySelector('.container').clientWidth * 0.95);
                    else
                        $("#runrate").height(document.querySelector('.container').clientWidth * 0.45);
                    var muliSeriesChartConfig = {
                        mainDiv: "#runrate",
                        colorRange: ["#1A223B", "#ec004f"],
                        data: data1,
                        columnsInfo: columnsInfo,
                        xAxis: "over",
                        yAxis: "run rate",
                        label: {
                            xAxis: "Over",
                            yAxis: "Run Rate"
                        },
                        requireCircle: false,
                        requireLegend: true
                    };
                    // console.log(muliSeriesChartConfig);
                    var muliSeriesChart2 = multiSeriesLineChart(muliSeriesChartConfig);
                }
            }
        },

    }
</script>
